<template>
  <el-dialog
    title="营业执照关联记录"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-table
        :data="listData"
        style="width: 100%"
        v-loading="loading"
        :border="true"
      >
        <el-table-column prop="supplier.fullname" align="center" label="联系人">
        </el-table-column>
        <el-table-column
          prop="img"
          align="center"
          label="营业执照图片"
          min-width="100"
        >
          <template slot-scope="scope" v-if="scope.row.img">
            <el-image
              style="width: 30px; height: 30px"
              :src="scope.row.img"
              :preview-src-list="[scope.row.img]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="关联时间" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleCancel(scope.row)"
              v-if="supplierInfo.id == scope.row.supplier_id"
              >取消关联
            </el-button>
            <el-button type="text" @click="authentication(scope.row)"
              >{{ scope.row.is_verify == 1 ? "取消认证" : "认证" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onHandleClose"> 关闭 </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getSupplierLicense,
  getSupplierLicenseDel,
  supplierlicenseverify,
} from "@/api/generalControl/index.js";
export default {
  name: "costConfigDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      loading: true,
      listData: [],
      supplierInfo: {}, // 选中该条供应商信息
    };
  },
  methods: {
    //认证取消认证
    authentication(e) {
      this.$confirm(
        `是否${e.is_verify == 2 ? `确认认证供货商${e.supplier.fullname}` : `取消认证供货商${e.supplier.fullname}`}？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          supplierlicenseverify({
            id: e.id,
            status: e.is_verify == 1 ? 2 : 1,
          }).then((res) => {
            this.$message({
              message: `已${e.is_verify == 1 ? "取消认证" : "认证成功"}`,
              type: "success",
              showClose: true,
            });
            e.is_verify=e.is_verify == 1 ? 2 : 1
            this.$emit("on-refresh-list"); // 刷新列表
          });
        })
        .catch(() => {});
    },

    /**
     * 取消关联
     */
    async handleCancel(row) {
      try {
        const res = await getSupplierLicenseDel({ id: row.id });
        this.$message.success("取消关联成功");
        this.$emit("on-refresh-list"); // 刷新列表
      } catch (error) {
        console.log(error, "getSupplierLicenseDel");
      } finally {
        this.onHandleClose(); // 关闭弹窗
      }
    },
    /**
     * 初始化
     */
    onInitData(data) {
      this.supplierInfo = data;
      this.dialogVisible = true;
      this.getAjaxSupplierLicense(data);
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
    },
    /**
     * 获取营业执照关联记录
     */
    async getAjaxSupplierLicense(e) {
      this.loading = true;
      try {
        const { data } = await getSupplierLicense({ reg_num: e.license.reg_num,supplier_id:e.id });
        this.listData = data;
      } catch (error) {
        console.log(error, "getSupplierLicense");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
    .config {
      &-base,
      &-before,
      &-after {
        display: flex;
        justify-content: center;
        .el-select,
        .el-input {
          flex: 1;
        }
        .label {
          width: 40px;
          padding-left: 20px;
        }
      }
      &-before {
        margin: 20px 0;
      }
    }
  }
}
</style>
