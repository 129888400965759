import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-供应商管理-修改提现状态
 * @param {*} params
 * @returns
 */
export function postSupplierWithdrawalStatus(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/withdrawalStatus`,
    data: params,
  });
}

/**
 * 总控-供应商管理-启用/禁用供应商
 * @param {*} params
 * @returns
 */
export function postSupplierUpdateState(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/supplierUpdateState`,
    data: params,
  });
}

/**
 * 总控-供应商管理-设置店铺
 * @param {*} params
 * @returns
 */
export function postSupplierShopName(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/shop`,
    data: params,
  });
}

/**
 * 总控-供应商管理-操作日志
 * @param {*} params
 * @returns
 */
export function getSupplierOperateLog(params = {}) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/supplier/operate/log`,
    params,
  });
}
