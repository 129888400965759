<template>
  <div class="supplier bj">
    <div class="title">
      <div class="seach">
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="inputs">
          <el-input
            v-model="from1.title"
            placeholder="请输入供货商名称"
            clearable
          ></el-input>
        </div>

        <div class="inputs">
          <el-input
            v-model="from1.supplier_fullname"
            placeholder="请输入联系人"
            clearable
          ></el-input>
        </div>

        <div class="inputs">
          <el-input
            v-model="from1.mobile"
            placeholder="请输入电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.bank_user_name"
            placeholder="请输入持卡人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.is_checkid"
            clearable
            placeholder="身份验证"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, vIndex) in verifyArr"
              :key="vIndex"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.buyer_fullname"
            placeholder="请输入对接业务采购"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select v-model="from1.state" clearable placeholder="请选择状态">
            <!-- <el-option label="全部状态" value=""></el-option> -->
            <el-option
              v-for="(sItem, sIndex) in stateList"
              :key="sIndex"
              :label="sItem.label"
              :value="sItem.value"
            />
          </el-select>
        </div>
        <!-- 平台服务费单独配置 start -->
        <!-- <div class="inputs">
          <el-select
            v-model="from1.is_platform_fee"
            clearable
            placeholder="平台服务费单独配置"
          >
            <el-option
              v-for="item of configList.filter((item) => item.value)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div> -->
        <!-- 平台服务费单独配置 end -->

        <!-- 增加提现状态 start -->
        <!-- <div class="inputs">
          <el-select
            v-model="from1.is_withdrawal"
            clearable
            placeholder="选择提现状态"
          >
            <el-option
              v-for="item of withdrawalList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div> -->
        <!-- 增加提现状态 end -->

        <!-- 采购类型 start -->
        <div class="inputs">
          <el-select
            v-model="from1.buyer_type"
            clearable
            placeholder="选择采购类型"
          >
            <el-option
              v-for="item of buyWayList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <!-- 采购类型 end -->
        <!-- 营业执照是否上传 start -->
        <div class="inputs" v-if="showLicense">
          <el-select
            v-model="from1.is_license"
            clearable
            placeholder="营业执照是否上传"
          >
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </div>
        <!-- 营业执照是否上传  end -->

        <div class="inputs">
          <el-select
            v-model="from1.is_verify_license"
            clearable
            placeholder="营业执照是否认证"
          >
            <el-option
              v-for="item of SUPPLIER_AUTHENTICATION_ENUM"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.is_commodity_quantity"
            clearable
            placeholder="是否限制坑位"
          >
            <el-option
              v-for="item of SUPPLIER_WHETHER"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs Twonumber">
          <el-input
            v-model="from1.commodity_quantity_min"
            placeholder="坑位数始"
            @blur="
              changeAmt(
                'commodity_quantity_min',
                'commodity_quantity_max',
                '坑位数'
              )
            "
            type="number"
          ></el-input>
          <span> 至 </span>
          <el-input
            v-model="from1.commodity_quantity_max"
            placeholder="坑位数止"
            @blur="
              changeAmt(
                'commodity_quantity_min',
                'commodity_quantity_max',
                '坑位数'
              )
            "
            type="number"
          ></el-input>
        </div>

        <div class="inputs">
          <el-select
            v-model="from1.contract_status"
            clearable
            placeholder="选择签约状态"
          >
            <el-option label="未签约" value="0"></el-option>
            <el-option label="签约中" value="1"></el-option>
            <el-option label="已签约" value="2"></el-option>
          </el-select>
        </div>
        <div>
          <el-date-picker
            type="datetimerange"
            clearable
            v-model="contract_time_value"
            start-placeholder="签约时间起"
            end-placeholder="签约时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="timechange"
          >
          </el-date-picker>
        </div>

        <div>
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="bjsearch"
            @click="Refresh"
          ></el-button>
          <el-button @click="search" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button type="primary" @click="buyerlistexport">导 出</el-button>
        </div>
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        ref="multipleTable"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="id" width="100px" align="center" label="ID">
        </el-table-column>
        <el-table-column prop="title" align="center" label="供货商名称">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.title) }}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="集配中心">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.logistic_business_name) }}
          </template>
        </el-table-column>
        <!-- 地采需求 start -->
        <!-- <el-table-column prop="buy_way" align="center" label="采购类型">
          <template slot-scope="scope">
            {{
              buyWayList.find(
                (item) =>
                  item.value === (scope.row.buy_way || BUY_WAY_ENUM.CITY.value)
              ).label
            }}
          </template>
        </el-table-column> -->
        <!-- 地采需求 end -->
        <el-table-column
          prop="buyer_type_txt	"
          align="center"
          label="对接采购类型"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.buyer_type_txt) }}
          </template>
        </el-table-column>
        <el-table-column prop="stall" align="center" label="档口位置">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.stall) }}
          </template>
        </el-table-column>
        <el-table-column prop="fullname" align="center" label="联系人">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.fullname) }}
          </template>
        </el-table-column>

        <el-table-column prop="mobile" align="center" label="联系电话">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.mobile) }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="对接业务采购">
          <template slot-scope="scope" v-if="scope.row.buyer">
            {{ $empty.empty(scope.row.buyer.fullname) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="supplier_bank.username"
          align="center"
          label="持卡人姓名"
        >
        </el-table-column>
        <el-table-column
          prop="card_checked_title"
          align="center"
          label="身份信息状态"
        >
        </el-table-column>
        <el-table-column prop="is_checkid" align="center" label="身份信息">
          <template slot-scope="scope">
            <span class="textWrap" @click="getIdCardInfo(scope.row)"
              >点击查看</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="license.reg_num"
          align="center"
          label="统一社会信用代码"
          v-if="showLicense"
        >
          <template slot-scope="scope">
            <span @click="onHandleBusinessLicense(scope.row)">
              {{ scope?.row?.license?.reg_num || "-" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="reg_num"
          align="center"
          label="营业执照"
          v-if="showLicense"
        >
          <template slot-scope="scope">
            <span
              class="textWrap"
              @click="onHandleBusinessLicense(scope.row)"
              v-if="scope?.row?.license?.reg_num"
              >点击查看</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="添加时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>

        <!-- 平台服务费单独配置 start -->
        <!-- <el-table-column
          prop="platform_fee_state"
          align="center"
          label="平台服务费单独配置"
        >
          <template slot-scope="scope">
            <span>
              {{
                configList.find(
                  (item) =>
                    item.value ===
                    (scope.row.platform_fee_state ||
                      PLATFORM_CONFIG_ENUM.null.value)
                ).label
              }}
            </span> -->
        <!-- 集配中心未设置时，不展示 start -->
        <!-- <el-tooltip
              v-if="
                scope.row.platform_fee_state &&
                scope.row.platform_fee_state !== PLATFORM_CONFIG_ENUM.null.value
              "
              class="item"
              effect="dark"
              :content="tooltipContent(scope.row)"
              :open-delay="600"
              placement="top"
            >
              <i class="el-icon-question" style="cursor: pointer"></i>
            </el-tooltip> -->
        <!-- 集配中心未设置时，不展示 end -->
        <!-- </template>
        </el-table-column>

        <el-table-column
          label="平台服务费"
          align="center"
          v-if="isEditPlatformFee"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="onHandleEditConfig(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column> -->
        <!-- 平台服务费单独配置 end -->

        <!-- 增加提现状态 start -->
        <el-table-column prop="is_withdrawal" align="center" label="提现状态">
          <template slot-scope="scope">
            <span class="text-btn" @click="onHandleWithdrawal(scope.row)">{{
              withdrawalList.find(
                (item) =>
                  item.value ===
                  (scope.row.is_withdrawal || IS_WITHDRAWAL.no.value)
              ).label
            }}</span>
          </template>
        </el-table-column>
        <!-- 增加提现状态 end -->
        <el-table-column prop="state" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.state == SUPPLIER_STATE_ENUM['2'].value
                  ? 'warning'
                  : 'success'
              "
            >
              {{
                stateList.find((tagItem) => tagItem.value == scope.row.state)
                  .tableName
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="commodity_quantity"
          align="center"
          label="坑位数"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.commodity_quantity == -1
                  ? "不限制"
                  : scope.row.commodity_quantity
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="onHandleChangeStatus(scope.row)">
              {{
                stateList.find((item) => item.value == scope.row.state)
                  .buttonName
              }}
            </el-button>
            <el-button type="text" @click="onHandleUpdate(scope.row)"
              >编辑
            </el-button>
            <el-button type="text" @click="onHandleViewLog(scope.row)"
              >操作记录
            </el-button>
            <el-button
              type="text"
              :disabled="disabledExportRow"
              @click="onHandleExportRow(scope.row)"
            >
              对账下载
            </el-button>
            <el-button
              type="text"
              :disabled="disabledExportRow"
              @click="Restart(scope.row)"
            >
              重新发起合同
            </el-button>
            <el-button
              type="text"
              :disabled="disabledExportRow"
              @click="Offline(scope.row)"
            >
              账号下线
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="签约状态" width="110">
          <template slot-scope="scope">
            <div>
              <div>{{ contract_status_cnt(scope.row.contract_status ) }}</div>
              <div> <el-button v-if="scope.row.contract_status==1||scope.row.contract_status==2" type="text" @click="gopath(scope.row)"
              >查看详情
            </el-button></div>
            <div>
              <el-button v-if="scope.row.contract_status != 2 && scope.row.is_contract==1" type="text" @click="Temporarywithdrawal(scope.row)"
              >临时提现
            </el-button>
            </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="签约时间" >
          <template slot-scope="scope">
            {{ scope.row.contract_time }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作记录" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="onHandleViewLog(scope.row)"
              >查看
            </el-button>
          </template>
        </el-table-column> -->
        <!-- 增加对账明细 start -->
        <!-- <el-table-column align="center" label="对账明细">
          <template slot-scope="scope">
            <el-button
              type="text"
              :disabled="disabledExportRow"
              @click="onHandleExportRow(scope.row)"
            >
              下载
            </el-button>
          </template>
        </el-table-column> -->
        <!-- 增加对账明细 end -->

        <!-- <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)"
              >查看自提点</el-button
            >
            <el-button type="text" @click="selectda(scope.$index, scope.row)"
              >新增自提点</el-button
            >
            <el-button type="text" @click="selectda(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!--
  新增中心账号 -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="500px"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="业务模块" prop="business_type_code">
            <div style="width: 302px">
              <el-select
                v-model="ruleForm.business_type_code"
                clearable
                placeholder="选择业务模块"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="业务名称" prop="itemdata">
            <div style="width: 302px">
              <el-select
                v-model="ruleForm.itemdata"
                clearable
                @change="changestore"
                placeholder="选择业务名称"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.id"
                  :label="item.name"
                  :value="JSON.stringify(item)"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="账号" prop="username">
            <div style="width: 302px">
              <el-input
                v-model="ruleForm.username"
                placeholder="请输入账号"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <div style="width: 302px">
              <el-input
                v-model="ruleForm.password"
                placeholder="请输入密码"
                clearable
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tianku">确认</el-button>
      </span>
    </el-dialog>
    <!--
  新增中心账号 -->
    <el-dialog
      title="身份信息"
      :visible.sync="showInfo"
      v-if="showInfo"
      width="500px"
      class="dialog"
      center
    >
      <div class="infoWrap">
        <div class="itemWrap">
          <div class="cellWrap">身份证正面</div>
          <div>
            <el-image
              class="imgWrap"
              :src="detailInfo.idcard_a"
              :preview-src-list="[detailInfo.idcard_a]"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
          <div
            v-if="detailInfo.idcard_a_download"
            class="cellWrap handWrap"
            @click="downLoad(detailInfo.idcard_a_download)"
          >
            点击下载
          </div>
          <el-button @click="handleStatus(-1)" type="primary" class="cellWrap">
            身份取消认证
          </el-button>
        </div>
        <div class="itemWrap">
          <div class="cellWrap">身份证反面</div>
          <div>
            <el-image
              class="imgWrap"
              :src="detailInfo.idcard_b"
              :preview-src-list="[detailInfo.idcard_b]"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
          <div
            v-if="detailInfo.idcard_b_download"
            class="cellWrap handWrap"
            @click="downLoad(detailInfo.idcard_b_download)"
          >
            点击下载
          </div>
          <el-button type="primary" class="cellWrap" @click="handleStatus(1)"
            >身份已认证</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 服务费配置 start -->
    <cost-config-dialog
      ref="refCostConfig"
      @on-edit-config="onChangeConfig"
    ></cost-config-dialog>
    <!-- 服务费配置 end -->

    <!-- 营业执照关联记录 start -->
    <business-license
      ref="refBusinessLicense"
      @on-refresh-list="onRefreshList"
    ></business-license>
    <!-- 营业执照关联记录 end -->

    <!-- 供应商店铺 start -->
    <supplier-store-dialog
      ref="refSupplierStoreDialog"
      @on-change="onChangeConfig"
    ></supplier-store-dialog>
    <!-- 供应商店铺 end -->

    <!-- 供应商禁用、启用、禁止提现、正常提现 start -->
    <supplier-edit-dialog
      ref="refSupplierEditDialog"
      @on-change="onRefreshList"
    ></supplier-edit-dialog>
    <!-- 供应商禁用、启用、禁止提现、正常提现 end -->

    <!-- 操作记录 start -->
    <supplier-log-dialog ref="refSupplierLogDialog"></supplier-log-dialog>
    <!-- 操作记录 end -->
  </div>
</template>
<script>
import {
  getUpdateIdStatus,
  getSupplierLicenseEnabled,
} from "@/api/generalControl/index.js";
import { PLATFORM_CONFIG_ENUM, IS_WITHDRAWAL } from "./utils/enum/index";
import {
  SUPPLIER_BUY_WAY_ENUM,
  SUPPLIER_AUTHENTICATION_ENUM,
  SUPPLIER_WHETHER,
} from "@/views/general/utils/enum";
import costConfigDialog from "./modules/cost-config-dialog/index.vue";
import businessLicense from "./modules/business-license/index.vue";
import SupplierStoreDialog from "./modules/supplier-store-dialog/index.vue";
import SupplierEditDialog from "./modules/supplier-edit-dialog/index.vue";
import SupplierLogDialog from "./modules/supplier-log-dialog/index.vue";
// import {
//   postSupplierWithdrawalStatus,
//   postSupplierUpdateState,
// } from "@/api/general/supplier";
import { downLoadFlowBlob } from "@/utils/tools/base";
import {
  postExportSupplierWallet,
  supplierexport,
} from "@/api/export/index.js";
import { SUPPLIER_STATE_ENUM } from "./utils/enum";

export default {
  name: "supplier",
  components: {
    costConfigDialog,
    businessLicense,
    SupplierStoreDialog,
    SupplierEditDialog,
    SupplierLogDialog,
  },
  data() {
    return {
      SUPPLIER_STATE_ENUM,
      stateList: Object.values(SUPPLIER_STATE_ENUM), //状态列表
      //列表新建页面初始参数
      loading: false,
      showInfo: false,
      detailInfo: {},
      IS_WITHDRAWAL,
      SUPPLIER_BUY_WAY_ENUM,
      SUPPLIER_AUTHENTICATION_ENUM,
      SUPPLIER_WHETHER,
      showLicense: false, // 是否显示营业执照
      withdrawalList: Object.values(IS_WITHDRAWAL), // 提现状态
      buyWayList: SUPPLIER_BUY_WAY_ENUM, // 采购类型下拉
      contract_time_value: [],
      from: {
        title: "",
        supplier_fullname: "",
        logistic_business_id: "",
        mobile: "",
        is_checkid: "",
        buyer_fullname: "",
        state: "",
        is_platform_fee: "",
        is_withdrawal: "",
        buyer_type: "", // 采购类型
        is_commodity_quantity: "", //	number	是否限制坑位数 0否 1是 空字符串或不传为所有
        commodity_quantity_min: "", //	number	坑位数最小值
        commodity_quantity_max: "", //	number	坑位数最大值
        is_verify_license: "", //	number	营业执照是否认证 0否 1是 空字符或不传为所有
        page: 1,
        pageSize: 10,
        is_license: "",
        contract_status: "",
        contract_time: "",
      },
      verifyArr: [
        { value: -1, label: "未验证" },
        { value: 1, label: "已验证" },
        { value: 0, label: "未上传" },
      ],
      PLATFORM_CONFIG_ENUM,
      configList: Object.values(PLATFORM_CONFIG_ENUM), // 平台服务费配置
      from1: {
        title: "",
        supplier_fullname: "",
        logistic_business_id: "",
        mobile: "",
        is_checkid: "",
        buyer_fullname: "",
        state: "",
        is_platform_fee: "",
        is_withdrawal: "",
        buyer_type: "", // 采购类型
        is_commodity_quantity: "", //	number	是否限制坑位数 0否 1是 空字符串或不传为所有
        commodity_quantity_min: "", //	number	坑位数最小值
        commodity_quantity_max: "", //	number	坑位数最大值
        is_verify_license: "", //	number	营业执照是否认证 0否 1是 空字符或不传为所有
        page: 1,
        pageSize: 10,
        is_license: "",
        contract_status: "",
        contract_time: "",
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      isEditPlatformFee: false, // 是否可编辑平台服务费
      //列表页面不同功能参数
      ruleForm: {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        itemdata: "",
        name: "",
        username: "",
        password: "",
      },
      title: "",
      centerDialogVisible: false,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        business_type_code: [
          { required: true, message: "请选择业务模块", trigger: "change" },
        ],
        itemdata: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
      },
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      options1: [],
      options: [],
      list: [],
      list1: [],
      // 集配中心列
      logisticsList: [],
      disabledExportRow: false,
    };
  },
  computed: {
    /**
     * tooltip 内容
     */
    tooltipContent() {
      return (data) => {
        const { state, now, default: defaults } = data.platform_fee;
        const propData =
          state === PLATFORM_CONFIG_ENUM.yes.value ? now : defaults;
        const {
          threshold_value,
          first_symbol_txt,
          first_amt,
          last_symbol_txt,
          last_amt,
        } = propData;
        const res = `结算条件：商品单价${first_symbol_txt}${threshold_value}元，每件${first_amt}元；商品单价${last_symbol_txt}${threshold_value}元，每件${last_amt}元`;
        return res;
      };
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout();
      console.log("do layout");
    });
  },
  created() {
    this.hqlist();
    this.getBusinessList();
    this.getAjaxSupplierLicenseEnabled();
    //   this.seletlist();
    // this.getAjaxLogisticsList();
  },
  methods: {
    Offline(e){
      this.$confirm('该供应商所有登录账号将下线，是否确认；', '账号下线', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.$api.general.supplierexpiredate({
            id:e.id
          }).then((res) => {
            this.hqlist();
            this.$message({
            type: 'success',
            message: '账号下线成功!'
          });
          })
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '账号下线取消'
          });          
        });
    },
    Temporarywithdrawal(e){
      this.$confirm('临时提现当天签约状态为无须签约，不需签约期间系统不扣除平台服务费，第二天状态系统改自动改成需签约；', '临时提现', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.$api.general.suppliercontractcancel({
            id:e.id
          }).then((res) => {
            this.hqlist();
            this.$message({
            type: 'success',
            message: '临时提现成功!'
          });
          })
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '临时提现取消'
          });          
        });
    },
    gopath(e){
      this.$router.push({
  path: '/signature',
  query:{
    id:e.id
  }
  
})
    },
    contract_status_cnt(e){
      switch (e) {
          case 1:
            return "签约中";

            break;
          case 2:
          return "已签约";
            break;
          default:
          return "未签约";
            break;
        }
    },
    timechange(val) {
      if (val) {
        this.from.contract_time = `${val[0]},${val[1]}`;
        this.from1.contract_time = `${val[0]},${val[1]}`;
      } else {
        this.from.contract_time = "";
        this.from1.contract_time = "";
      }
    },
    /**
     * 金额发生改变
     */
    changeAmt(val1, val2, name) {
      if (
        this.from1[val1] &&
        this.from1[val2] &&
        Number(this.from1[val1]) > Number(this.from1[val2])
      ) {
        this.$message.warning(`起始${name}不得大于截止${name}`);
        this.from1[val2] = "";
      }
    },
    //导出
    buyerlistexport() {
      let params = {
        ...this.from,
      };
      delete params.page;
      delete params.pageSize;

      supplierexport(params).then((res) => {
        console.log(res);
        downLoadFlowBlob(res, res?.filename);
      });
    },
    /**
     * 重新发起合同
     */
    async Restart(row) {
      this.$confirm("确定要重新发起吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$api.general.contractrestart({id:row.id}).then((res)=>{
        this.$message.success("操作成功");
        this.hqlist();
      })
        })
        .catch(() => {});
      
    },
    /**
     * 对账下载
     */
    async onHandleExportRow(row) {
      this.disabledExportRow = true;
      this.$message.success({
        message: "正在导出，请耐心等待",
        duration: 999,
      });
      try {
        const params = {
          supplier_id: row.id,
        };
        const res = await postExportSupplierWallet(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax postExportSupplierWallet", err);
      } finally {
        this.$message.closeAll();
        this.disabledExportRow = false;
      }
    },
    /**
     * 编辑
     */
    onHandleUpdate(row) {
      this.$refs.refSupplierStoreDialog.onInitData(row);
    },
    /**
     * 查看操作记录
     */
    onHandleViewLog(row) {
      this.$refs.refSupplierLogDialog.onInitData(row);
    },
    /**
     * 编辑平台服务费配置
     */
    onHandleEditConfig(row) {
      const { logistic_business_id, id, platform_fee } = row;
      const data = {
        logisticsId: logistic_business_id,
        supplierId: id,
        ...platform_fee,
      };
      this.$refs.refCostConfig.onInitData(data);
    },
    /**
     * 查看营业执照
     */
    onHandleBusinessLicense(row) {
      this.$refs.refBusinessLicense.onInitData(row);
    },
    /**
     * 只有运营总和管理员 有权限查看营业执照
     */
    async getAjaxSupplierLicenseEnabled() {
      try {
        const res = await getSupplierLicenseEnabled();
        this.showLicense = res.data;
      } catch (error) {
        console.log(error, "getSupplierLicenseEnabled");
      }
    },
    /**
     * 刷新数据
     */
    onRefreshList() {
      this.hqlist();
    },
    /**
     * 修改了平台服务费配置
     */
    onChangeConfig() {
      this.hqlist();
    },
    // 身份认证 二次弹窗
    handleStatus(type) {
      this.$confirm(
        type == 1 ? "确定要身份已认证吗？" : "确定要身份取消认证吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.getAjaxUpdateIdStatus(type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 身份认证状态修改
    async getAjaxUpdateIdStatus(type) {
      try {
        const res = await getUpdateIdStatus({
          id: this.detailInfo.id,
          is_checkid: type,
        });
        this.$message({
          message: "更新数据成功",
          type: "success",
        });
        console.log("ajax getUpdateIdStatus", res);
      } catch (err) {
        console.log("ajax getUpdateIdStatus err", err);
      } finally {
        this.showInfo = false;
        this.Refresh();
      }
    },
    async downLoad(url) {
      let aLink = document.createElement("a");
      aLink.style.display = "none";
      const arr = url.split(".");
      aLink.href = url;
      aLink.download = `身份信息.${arr[arr.length - 1]}`;
      document.body.appendChild(aLink);
      console.log(aLink, arr, "==----");
      aLink.click();
      document.body.removeChild(aLink);
    },
    /**
     * 查看身份信息
     */
    getIdCardInfo(row) {
      if (!row.idcard_a || !row.idcard_b) {
        this.$message.warning("暂无身份信息，无法查看！");
        return;
      }
      this.showInfo = true;
      this.detailInfo = row;
    },
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    search() {
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.hqlist();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    addclick() {
      console.log("新增");

      this.ruleForm = {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        name: "",
        itemdata: "",
        username: "",
        password: "",
      };

      this.centerDialogVisible = true;
      this.title = "新增中心账号";
    },
    tianku() {
      console.log(this.ruleForm);
      this.centerDialogVisible = false;
      this.$api.login.addbusiness(this.ruleForm).then((res) => {
        this.$message({
          type: "success",
          message: "添加成功",
        });
        this.Refresh();
      });
    },
    Refresh() {
      this.from = {
        title: "",
        supplier_fullname: "",
        logistic_business_id: "",
        mobile: "",
        is_checkid: "",
        buyer_fullname: "",
        state: "",
        is_platform_fee: "",
        is_withdrawal: "",
        buyer_type: "", // 采购类型
        is_commodity_quantity: "", //	number	是否限制坑位数 0否 1是 空字符串或不传为所有
        commodity_quantity_min: "", //	number	坑位数最小值
        commodity_quantity_max: "", //	number	坑位数最大值
        is_verify_license: "", //	number	营业执照是否认证 0否 1是 空字符或不传为所有
        page: 1,
        pageSize: 10,
        is_license: "",
        contract_status: "",
        contract_time: "",
      };
      this.from1 = {
        title: "",
        supplier_fullname: "",
        logistic_business_id: "",
        mobile: "",
        is_checkid: "",
        buyer_fullname: "",
        state: "",
        is_platform_fee: "",
        is_withdrawal: "",
        buyer_type: "", // 采购类型
        is_commodity_quantity: "", //	number	是否限制坑位数 0否 1是 空字符串或不传为所有
        commodity_quantity_min: "", //	number	坑位数最小值
        commodity_quantity_max: "", //	number	坑位数最大值
        is_verify_license: "", //	number	营业执照是否认证 0否 1是 空字符或不传为所有
        page: 1,
        pageSize: 10,
        is_license: "",
        contract_status: "",
        contract_time: "",
      };
      this.contract_time_value = [];
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    /**
     * 禁用/启用
     */
    onHandleChangeStatus(row) {
      this.$refs.refSupplierEditDialog.onInitData(row, "disabled");
      // this.$confirm(
      //   row.state === this.SUPPLIER_STATE_ENUM["1"].value
      //     ? `您将禁用供应商【${row.title}】, 禁用后将不可登录,是否确认？`
      //     : `您将解禁供应商【${row.title}】, 解禁后可正常登录,是否确认？`,
      //   "提示",
      //   {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //     center: true,
      //   }
      // )
      //   .then(async () => {
      //     try {
      //       const res = await postSupplierUpdateState({
      //         id: row.id,
      //         state:
      //           row.state === this.SUPPLIER_STATE_ENUM["1"].value
      //             ? this.SUPPLIER_STATE_ENUM["2"].value
      //             : this.SUPPLIER_STATE_ENUM["1"].value,
      //       });
      //       console.log(res, "禁用或者启用");
      //       this.$message({
      //         type: "success",
      //         message:
      //           row.state === this.SUPPLIER_STATE_ENUM["1"].value
      //             ? "禁用成功"
      //             : "启用成功",
      //       });
      //       this.hqlist();
      //     } catch (error) {
      //       console.log(error, "postSupplierUpdateState");
      //     }
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message:
      //         row.state === this.SUPPLIER_STATE_ENUM["1"].value
      //           ? "取消禁用"
      //           : "取消启用",
      //     });
      //   });
    },

    selectda(index, row) {
      this.title = "修改中心账号";
      this.ruleForm.business_type_code = row.business_type_code;
      //修改参数显示不全，问题
      // if (row.business_type_code == "logistics") {
      //   this.list.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // } else {
      //   this.list1.forEach((el) => {
      //     if (el.id == row.store_id) {
      //       this.ruleForm.itemdata = JSON.stringify(el);
      //     }
      //   });
      // }
      console.log(this.ruleForm, this.list, this.list);
      this.centerDialogVisible = true;
      // this.shownode = false;
      // this.$api.shipper.shipperinfo({ id: row.id }).then((res) => {
      //   console.log(res, "获取数据");
      //   this.info = res.data;
      //
      // });
    },
    hqlist() {
      this.loading = true;
      this.$api.general.supplierList(this.from).then((res) => {
        const { data, total, is_edit_platform_fee } = res.data;
        console.log(res, "获取数据");
        this.tableData = data;
        this.total = total;
        this.isEditPlatformFee = is_edit_platform_fee;
        this.loading = false;
      });
    },
    seletlist() {
      this.$api.login.unusedall().then((res) => {
        console.log(res, "获取所有可配置的自提点");
        this.list1 = res.data;
      });
      this.$api.login.logisticsunusedall().then((res) => {
        console.log(res, "获取所有可配置的城市仓");

        this.list = res.data;
      });
    },
    /**
     * 操作提现状态
     */
    onHandleWithdrawal(row) {
      this.$refs.refSupplierEditDialog.onInitData(row, "withdrawal");
      // const { title, is_withdrawal } = row;
      // const hint = is_withdrawal === IS_WITHDRAWAL.yes.value ? "禁止" : "允许";
      // const msg = `您将${hint}供应商【${title}】提现，是否确认？`;
      // this.$confirm(msg, "提示", {
      //   center: true,
      //   confirmButtonText: "确 认",
      //   cancelButtonText: "取 消",
      //   customClass: "jn-confirm-message-style",
      //   iconClass: "el-icon-warning",
      // })
      //   .then(() => this.ajaxPostSupplierWithdrawalStatus(row))
      //   .catch();
    },
    /**
     * 供应商提现状态
     */
    async ajaxPostSupplierWithdrawalStatus(row) {
      const { id, is_withdrawal } = row;
      const withdrawal = 0;
      is_withdrawal === IS_WITHDRAWAL.yes.value
        ? IS_WITHDRAWAL.no.value
        : IS_WITHDRAWAL.yes.value;
      const params = {
        id,
        is_withdrawal: withdrawal,
      };
      try {
        await postSupplierWithdrawalStatus(params);
        // 成功后修改值，减少一次接口请求
        const rowIndex = this.tableData.findIndex((item) => item.id === id);
        this.tableData[rowIndex].is_withdrawal = withdrawal;
        const msg =
          is_withdrawal === IS_WITHDRAWAL.yes.value ? "禁用成功" : "启用成功";
        this.$message.success(msg);
      } catch (err) {
        console.log("ajax postSupplierWithdrawalStatus err", err);
      }
    },
  },
};
</script>
<style lang="scss">
.supplier {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .textWrap {
    color: rgb(64, 158, 255);
    cursor: pointer;
  }
  .infoWrap {
    display: flex;
    .itemWrap {
      width: 50%;
      text-align: center;
      .cellWrap {
        margin: 10px 0px;
      }
      .imgWrap {
        width: 150px;
        height: 150px;
      }
      .handWrap {
        cursor: pointer;
      }
      .image-slot {
        text-align: center;
        background: #f5f7fa;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
      }
    }
  }
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .seach {
      flex: 1;
      gap: 20px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: flex-end;
      .inputs {
        width: 207px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
// .el-picker-panel {
//   left: 1245px !important;
// }
.Twonumber {
  display: flex;
  align-items: center;
  width: 300px !important;
  span {
    margin: 0 5px !important;
  }
}
</style>
