<template>
  <el-dialog
    title="平台服务费配置"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="60%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        label-width="170px"
        ref="refForm"
        v-loading="dialogLoading"
        :model="formData"
        :rules="formRules"
      >
        <el-form-item label="单独配置规则：" prop="state">
          <el-radio-group v-model="formData.state" @change="onChangeRadio">
            <el-radio
              v-for="item of configList"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item label="平台服务费：" prop="costConfig">
          <!-- 单独配置 start -->
          <div
            v-if="formData.state === PLATFORM_CONFIG_ENUM.yes.value"
            class="config"
          >
            <div class="config-base">
              <span>单价基点：</span>
              <el-input
                v-model="formData.now.threshold_value"
                placeholder="请输入单价基点"
                clearable
                ><span slot="suffix">元</span></el-input
              >
            </div>
            <div class="config-before">
              <el-select
                @change="onChangeSymbol('first_symbol')"
                v-model="formData.now.first_symbol"
                clearable
                placeholder="选择条件"
              >
                <el-option
                  :label="SYMBOL_ENUM.first.label"
                  :value="SYMBOL_ENUM.first.value"
                ></el-option>
                <el-option
                  :label="SYMBOL_ENUM.second.label"
                  :value="SYMBOL_ENUM.second.value"
                ></el-option>
              </el-select>
              <div class="label">基点</div>
              <el-input
                v-model="formData.now.first_amt"
                placeholder="请输入金额"
                clearable
                ><span slot="suffix">元/件</span></el-input
              >
            </div>
            <div class="config-after">
              <el-select
                @change="onChangeSymbol('last_symbol')"
                v-model="formData.now.last_symbol"
                clearable
                placeholder="选择条件"
              >
                <el-option
                  :label="SYMBOL_ENUM.thirdly.label"
                  :value="SYMBOL_ENUM.thirdly.value"
                ></el-option>
                <el-option
                  :label="SYMBOL_ENUM.fourthly.label"
                  :value="SYMBOL_ENUM.fourthly.value"
                ></el-option>
              </el-select>
              <div class="label">基点</div>
              <el-input
                v-model="formData.now.last_amt"
                placeholder="请输入金额"
                clearable
                ><span slot="suffix">元/件</span></el-input
              >
            </div>
          </div>
          <!-- 单独配置 end -->

          <!-- 非单独配置 start -->
          <div v-else class="config">
            <div class="config-base">
              <span>单价基点：</span>
              <el-input
                v-model="formData.default.threshold_value"
                :disabled="true"
                placeholder="请输入单价基点"
                clearable
                ><span slot="suffix">元</span></el-input
              >
            </div>
            <div class="config-before">
              <el-select
                v-model="formData.default.first_symbol"
                :disabled="true"
                clearable
                placeholder="选择条件"
              >
                <el-option
                  :label="SYMBOL_ENUM.first.label"
                  :value="SYMBOL_ENUM.first.value"
                ></el-option>
                <el-option
                  :label="SYMBOL_ENUM.second.label"
                  :value="SYMBOL_ENUM.second.value"
                ></el-option>
              </el-select>
              <div class="label">基点</div>
              <el-input
                v-model="formData.default.first_amt"
                :disabled="true"
                placeholder="请输入金额"
                clearable
                ><span slot="suffix">元/件</span></el-input
              >
            </div>
            <div class="config-after">
              <el-select
                v-model="formData.default.last_symbol"
                :disabled="true"
                clearable
                placeholder="选择条件"
              >
                <el-option
                  :label="SYMBOL_ENUM.thirdly.label"
                  :value="SYMBOL_ENUM.thirdly.value"
                ></el-option>
                <el-option
                  :label="SYMBOL_ENUM.fourthly.label"
                  :value="SYMBOL_ENUM.fourthly.value"
                ></el-option>
              </el-select>
              <div class="label">基点</div>
              <el-input
                v-model="formData.default.last_amt"
                :disabled="true"
                placeholder="请输入金额"
                clearable
                ><span slot="suffix">元/件</span></el-input
              >
            </div>
          </div>
          <!-- 非单独配置 end -->
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
// import cloneDeep from "lodash/cloneDeep";
import { PLATFORM_CONFIG_ENUM, SYMBOL_ENUM } from "../../utils/enum/index";
import { ajaxPostAddCostConfig } from "@/api/general/index";
import { validInteger, validMoney } from "@/utils/tools/validate";

export default {
  name: "costConfigDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      PLATFORM_CONFIG_ENUM,
      SYMBOL_ENUM,
      configList: Object.values(PLATFORM_CONFIG_ENUM).filter(
        (item) => item.value
      ),
      logisticsId: "", // 集配中心id
      supplierId: "", // 供应商id
      formData: {
        state: "", // 是否单独配置
        now: {
          threshold_value: "", // 单价基点
          first_symbol: "", // 第一个条件
          first_amt: "", // 第一个条件，取值
          last_symbol: "", // 第二个条件
          last_amt: "", // 第二个条件。取值
        }, // 单配配置
        default: {
          threshold_value: "",
          first_symbol: "",
          first_amt: "",
          last_symbol: "",
          last_amt: "",
        }, // 非单独配置，复用集配中心的配置
      },
      symbolRules: {
        first: {
          first_symbol: SYMBOL_ENUM.second.value,
          last_symbol: SYMBOL_ENUM.thirdly.value,
        },
        second: {
          first_symbol: SYMBOL_ENUM.first.value,
          last_symbol: SYMBOL_ENUM.fourthly.value,
        },
      }, // 运算规则
      submitLoading: false, // 提交按钮禁用
      formRules: {
        /** 单价基点，校验 */
        costConfig: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              // 匹配数据
              const { state, now } = this.formData;
              const {
                threshold_value,
                first_symbol,
                first_amt,
                last_symbol,
                last_amt,
              } = now;
              // 否，无需校验
              if (state === PLATFORM_CONFIG_ENUM.no.value) {
                callback();
              }
              if (!threshold_value) {
                callback(new Error("请输入单价基点！"));
              }
              if (!first_symbol || !first_amt || !last_symbol || !last_amt) {
                callback(new Error("基点条件、金额需填完整！"));
              }
              if (validInteger(threshold_value)) {
                callback(new Error("单价基点需为大于等于0的整数！"));
              }
              if (!validMoney(first_amt) || !validMoney(last_amt)) {
                callback(new Error("基点需大于等于0，允许两位小数点！"));
              }
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      data && this.onInitEditForm(data);
      this.$refs.refForm?.clearValidate();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 初始化编辑页面
     */
    onInitEditForm(data) {
      console.log("🆒 onInitEditForm", data);
      this.dialogLoading = true;
      const { logisticsId, supplierId, state, now, default: defaults } = data;
      this.logisticsId = logisticsId;
      this.supplierId = supplierId;
      this.formData.state = state;
      if (Object.values(now).length) {
        this.formData.now = now;
      }
      if (Object.values(defaults).length) {
        this.formData.default = defaults;
      }
      this.formData.now.threshold_value =
        now.threshold_value && parseInt(now.threshold_value);
      this.dialogLoading = false;
    },
    /**
     * 切换了选项
     */
    onChangeRadio() {
      this.$refs.refForm?.clearValidate();
    },
    /**
     * 修改了条件
     */
    onChangeSymbol(key) {
      const propData = this.formData.now;
      if (!propData[key]) {
        return;
      }
      // 最终修改的值
      let nextProp = {};
      Object.values(this.symbolRules).forEach((child) => {
        // 取规则里的数据
        if (propData[key] === child[key]) {
          nextProp = {
            ...propData,
            ...child,
          };
        }
      });
      this.formData.now = {
        ...nextProp,
      };
      // console.log("🆒 onChangeSymbol", propData, nextProp);
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        this.ajaxPostUpdateCostConfig();
      });
    },
    /**
     * 平台服务费配置，保存接口
     */
    async ajaxPostUpdateCostConfig() {
      const { state, now } = this.formData;
      const params = {
        // logistic_business_id: this.logisticsId,
        supplier_id: this.supplierId,
        state,
      };
      // 如果选择了是，则添加自定义配置
      if (state === PLATFORM_CONFIG_ENUM.yes.value) {
        Object.assign(params, now);
        delete params.first_symbol_txt;
        delete params.last_symbol_txt;
      }
      console.log("🆒 ajax ajaxPostAddCostConfig", params);
      try {
        await ajaxPostAddCostConfig(params);
        this.$message.success("编辑成功");
        this.onHandleClose();
        this.$emit("on-edit-config");
      } catch (err) {
        console.log("ajax ajaxPostAddCostConfig err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
    .config {
      &-base,
      &-before,
      &-after {
        display: flex;
        justify-content: center;
        .el-select,
        .el-input {
          flex: 1;
        }
        .label {
          width: 40px;
          padding-left: 20px;
        }
      }
      &-before {
        margin: 20px 0;
      }
    }
  }
}
</style>
