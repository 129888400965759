var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "营业执照关联记录",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "60%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.listData, border: true },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "supplier.fullname",
                  align: "center",
                  label: "联系人",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "img",
                  align: "center",
                  label: "营业执照图片",
                  "min-width": "100",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return scope.row.img
                          ? [
                              _c("el-image", {
                                staticStyle: { width: "30px", height: "30px" },
                                attrs: {
                                  src: scope.row.img,
                                  "preview-src-list": [scope.row.img],
                                },
                              }),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  label: "关联时间",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.supplierInfo.id == scope.row.supplier_id
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCancel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("取消关联 ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.authentication(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.is_verify == 1 ? "取消认证" : "认证"
                              ) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleClose } },
            [_vm._v(" 关闭 ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }